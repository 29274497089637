.rules {
    background: #00112A;

    .participateBtnBg {
        box-shadow: 0px 0px 50 px #721DDD, outset 0px 0px 20px #3AA3E2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        width: 250px;
    }

    .participateBtn {
        background: #DDEEF8;
        box-shadow: 0px 0px 15px #4395D5, inset 0px 0px 20px rgba(255, 255, 255, 0.9);
        border-radius: 43px;
        width: 250px;

        span {
            font-size: 16px;
            line-height: 23px;
            font-weight: 600;
            background: linear-gradient(92.32deg, #721DDD 4.16%, #3AA3E2 98.76%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-shadow: 0px 0px 9px #7DCCFF;

            @media (max-width: 991px) {
                font-size: 18px;
                line-height: 23px;
            }

            @media (max-width: 677px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .rulesContent {
        position: sticky;
        z-index: 3;
    }

    .standingSection {
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 40px;
        position: sticky;
        z-index: 1;
    }

    .prizeBg {
        background-image: url(../img/standings/prizeBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: left;
        mix-blend-mode: screen;
        min-height: 80vh;

        @media (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .text-gold {
        color: #F2C94C;
    }

    .robotBg {
        min-height: 70vh;
    }

    .robotBody {
        width: 60%;

        @media (max-width: 677px) {
            width: 70%;
        }
    }

    .light1Pos {
        position: absolute;
        top: 3%;
        right: 16%;
        mix-blend-mode: screen;
        width: 50%;

        @media (max-width: 677px) {
            right: 5%;
            top: 7%;
        }
    }

    .imageBoxImgSize {
        width: 20%;
    }

    .imageBoxTitle {
        font-weight: 700;
        font-size: 19px;
        line-height: 25px;
        text-transform: uppercase;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 19px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 17px;
            line-height: 22px;
        }
    }

    .imageBoxDesc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 19px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }
    
    .sectionTitleText {
        font-weight: 900;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }
    }

    .sectionTitleText2 {
        font-weight: 900;
        font-size: 36px;
        line-height: 40px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }
    }

    .sectionDescText {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 18px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .pariticipateIconSize {
        width: 90px;
        height: 100px;
    }

    .contactUsBg {
        background-image: url(../img/standings/contactUsBg.png);
        background-size: 100% 100%;
        background-position: center;
        min-height: 70vh;

        @media (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .lightFade {
        animation-name: LightFade;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-top: 5px;
    }

    @keyframes LightFade {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .rulesBgDecPos1 {
        position: absolute;
        top: 0%;
        left: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .rulesBgDecPos2 {
        position: absolute;
        top: 0%;
        right: 0;

        @media (max-width: 991px) {
            top: 50%;
            right: 0;
        }

        @media (max-width: 667px) {
            top: 80%;
            right: 0;
        }

        img {
            mix-blend-mode: screen;
        }
    }

    .accordion__button {
        background-color: transparent !important;
    }

    .accordionTitle {
        font-family: 'Urbanist-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
        color: #fff;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText {
        font-family: 'Urbanist-Bold';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #fff;
        width: fit-content;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .accordionTitle-inactive {
        font-family: 'Urbanist-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 29px;
        color: #fff;

        @media (max-width: 667px) {
            font-weight: 500;
            font-size: 16px;
        }
    }

    .accordionSideItemText-inactive {
        font-family: 'Urbanist-Bold';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #fff;

        @media (max-width: 667px) {
            font-weight: 400;
            font-size: 12px;
        }
    }


    .accordionContent {
        font-family: 'Urbanist-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        text-align: left;
        color: #fff;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 12px;
            line-height: 22px;
        }
    }


    .accordion__button {
        background: transparent
    }

    .accordion__panel {
        padding-left: 8px;
        padding-top: 4px !important;
    }


    .accordion__button:before {
        display: none;
    }

    .lh-1 {
        line-height: 1;
    }

    .accordion__button::before {
        display: none !important;
    }

    .accordionIcon {
        font-size: 18px;
    }

    .selectedExpandIcon {
        border-radius: 50%;
        padding: 8px;
        width: fit-content;
        text-align: center;
        background: linear-gradient(92.32deg, #721DDD 4.16%, #3AA3E2 98.76%);
        margin: auto;
    }

    .nonselectedExpandIcon {
        border-radius: 50%;
        padding: 8px;
        width: fit-content;
        text-align: center;
        background: #DFEFF9;
        margin: auto;
    }


    .borderBottomWhite {
        border-bottom: 1px solid #334170;
    }

    .borderTopWhite {
        border-top: 1px solid #334170;
    }

    .copy-box2 {
        position: relative;
        display: inline-block;
        margin: 0 50px 0 0px;
        width: fit-content;
        height: 300px;
    }

    .copy-box2 .line {
        position: absolute;
        background-color: aqua;
        box-shadow: 0px 0px 12px aqua;
        width: 40px;
        height: calc(100% - 20px);
        top: 10px;
        left: 22px;
        border-radius: 50%;
        overflow: hidden;
    }

    .copy-box2.two .line {
        border-radius: 0;
        width: 2px;
    }

    .copy-box2 .line .scanner {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        width: 100%;
        height: 20px;
        border-radius: 50%;
        animation: scanner-loop 3s ease-in-out infinite;
    }

    .copy-box2.two .line .scanner {
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        height: 50%;
    }

    @keyframes scanner-loop {
        0% {
            top: 0;
        }

        50% {
            top: 100%;
        }

        100% {
            top: 0;
        }
    }

    .buttonGradientCenterAnim {

        &::after {
            position: absolute;
            content: "";
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;
            height: 50px;
            width: 220px;
            transform: scale(0.9) translateZ(0);
            filter: blur(15px);
            background: linear-gradient(to left,
                    #ff5770,
                    #e4428d,
                    #c42da8,
                    #9e16c3,
                    #6501de,
                    #9e16c3,
                    #c42da8,
                    #e4428d,
                    #ff5770);
            background-size: 200% 200%;
            animation: animateGlow 1.25s linear infinite;
        }
    }

    .buttonGradientLeftAnim {
        &::after {
            position: absolute;
            content: "";
            top: 40px;
            left: 0;
            z-index: 0;
            height: 50px;
            width: 220px;
            transform: scale(0.9) translateZ(0);
            filter: blur(15px);
            background: linear-gradient(to left,
                    #ff5770,
                    #e4428d,
                    #c42da8,
                    #9e16c3,
                    #6501de,
                    #9e16c3,
                    #c42da8,
                    #e4428d,
                    #ff5770);
            background-size: 200% 200%;
            animation: animateGlow 1.25s linear infinite;
        }

    }

    @keyframes animateGlow {
        0% {
            background-position: 0% 50%;
        }

        100% {
            background-position: 200% 50%;
        }
    }

    .circle-container {
        $particleWidth: 15px;
        $particleNum: 50;
        $particleColor: hsl(50, 100%, 80%);
        $particleColorA: hsla(50, 100%, 80%, 0);

        position: fixed;
        transform: translateY(-10vh);
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        z-index: 10000;

        .circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            mix-blend-mode: screen;
            background-image: radial-gradient($particleColor,
                    $particleColor 10%,
                    $particleColorA 56%);

            animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

            @keyframes fade-frames {
                0% {
                    opacity: 1;
                }

                50% {
                    opacity: 0.7;
                }

                100% {
                    opacity: 1;
                }
            }

            @keyframes scale-frames {
                0% {
                    transform: scale3d(0.4, 0.4, 1);
                }

                50% {
                    transform: scale3d(2.2, 2.2, 1);
                }

                100% {
                    transform: scale3d(0.4, 0.4, 1);
                }
            }
        }

        @for $i from 1 through $particleNum {
            &:nth-child(#{$i}) {
                $circleSize: random($particleWidth);
                width: $circleSize + px;
                height: $circleSize + px;

                $startPositionY: random(10)+100;
                $framesName: "move-frames-"+$i;
                $moveDuration: 7000+random(5000)+ms;

                animation-name: #{$framesName};
                animation-duration: $moveDuration;
                animation-delay: random(11000) + ms;

                @keyframes #{$framesName} {
                    from {
                        transform: translate3d(#{random(100) + vw},
                            #{$startPositionY + vh},
                            0);
                    }

                    to {
                        transform: translate3d(#{random(100) + vw},
                            #{- $startPositionY - random(30) + vh},
                            0);
                    }
                }

                .circle {
                    animation-delay: random(5000) + ms;
                }
            }
        }
    }

    .termsBgDecPos1 {
        position: absolute;
        top: 0%;
        left: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .termsBgDecPos2 {
        position: absolute;
        top: 10%;
        right: 0;

        @media (max-width: 991px) {
            top: 40%;
        }

        img {
            mix-blend-mode: screen;
        }
    }

    .termsBgDecPos3 {
        position: absolute;
        bottom: 0%;
        left: 0;

        img {
            mix-blend-mode: screen;
        }
    }

}
