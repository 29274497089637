.home {
    background: #00112A;

    .headerSection {
        min-height: 110vh;
        background-image: url(../img/home/headerBg.png);
        background-size: 100% 100%;

        @media (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .headerTitle {
        font-weight: 900;
        font-size: 96px;
        line-height: 120px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 0px 23px rgba(44, 44, 44, 0.45);

        @media (max-width: 991px) {
            font-size: 60px;
            line-height: 70px;
        }

        @media (max-width: 677px) {
            font-size: 40px;
            line-height: 60px;
        }
    }

    .headerSubTitle {
        font-weight: 600;
        font-size: 28px;
        line-height: 50px;
        text-align: center;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);

        @media (max-width: 991px) {
            font-size: 23px;
            line-height: 30px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    .headerDesc {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 18px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .enrollBtnBg {
        box-shadow: 0px 0px 50 px #721DDD, outset 0px 0px 20px #3AA3E2;
        width: fit-content;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 1;
        width: 220px;
    }

    .enrollBtn {
        background: #DDEEF8;
        box-shadow: 0px 0px 15px #4395D5, inset 0px 0px 20px rgba(255, 255, 255, 0.9);
        border-radius: 43px;
        width: 220px;

        span {
            font-size: 18px;
            line-height: 23px;
            font-weight: 600;
            background: linear-gradient(92.32deg, #721DDD 4.16%, #3AA3E2 98.76%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            text-shadow: 0px 0px 9px #7DCCFF;

            @media (max-width: 991px) {
                font-size: 18px;
                line-height: 23px;
            }

            @media (max-width: 677px) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }

    .prizeSection {
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 40px;
        margin-top: -20vh;
        position: sticky;
        z-index: 1;
    }

    .prizeBg {
        background-image: url(../img/home/prizePoolTrophy.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left;
        mix-blend-mode: screen;

        @media (max-width: 991px) {
            background-position: center;
        }

        @media (max-width: 677px) {
            background-position: left;
        }
    }

    .PrizeTopTitle {
        font-weight: 900;
        font-size: 1.3vw;
        line-height: 1.5vw;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 22px;
            line-height: 28px;
        }

        @media (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }


    .PrizeTitle {
        font-weight: 900;
        font-size: 2.8vw;
        line-height: 3vw;
        text-align: center;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 50px;
            line-height: 60px;
        }

        @media (max-width: 677px) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    .text-gold {
        color: #F2C94C;
    }

    .text-blue-shadow {
        text-shadow: 0px 0px 15px #7DCCFF;
    }

    .robotBg {
        min-height: 70vh;
    }

    .robotBody {
        width: 100%;

        @media (max-width: 677px) {
            width: 90%;
        }
    }

    .touch1Pos {
        position: absolute;
        top: -10%;
        right: 5%;
        mix-blend-mode: screen;
        width: 25%;

        @media (max-width: 677px) {
            right: 15%;
            top: -15%;
        }
    }

    .touch2Pos {
        position: absolute;
        top: 25%;
        right: -5%;
        mix-blend-mode: screen;
        width: 25%;

        @media (max-width: 677px) {
            right: 5%;
        }
    }

    .imageBoxImgSize {
        width: 20%;
    }

    .imageBoxTitle {
        font-weight: 700;
        font-size: 19px;
        line-height: 25px;
        text-transform: uppercase;
        color: #FFFFFF;

        @media (max-width: 991px) {
            font-size: 19px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 17px;
            line-height: 22px;
        }
    }

    .imageBoxDesc {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 19px;
            line-height: 25px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .enrollTitleText {
        font-weight: 700;
        font-size: 36px;
        line-height: 45px;

        @media (max-width: 991px) {
            font-size: 36px;
            line-height: 45px;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 32px;
        }
    }

    .sectionTitleText {
        font-weight: 900;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 991px) {
            font-size: 30px;
            line-height: 160%;
        }

        @media (max-width: 677px) {
            font-size: 25px;
            line-height: 160%;
        }
    }

    .sectionDescText {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: center;

        @media (max-width: 991px) {
            font-size: 18px;
            line-height: 23px;
        }

        @media (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

    .advantagesBox {
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        min-height: 330px;
        display: flex;
        align-items: center;
        height: 100%;
        position: sticky;
        z-index: 1;

        @media (max-width: 991px) {
            min-height: 490px;
        }

        @media (max-width: 677px) {
            min-height: 400px;
        }
    }

    .paddingCol-2 {
        padding-top: 30vh;

        @media (max-width: 991px) {
            padding-top: 0vh;
        }

    }

    .advantageBoxBgLeft1 {
        z-index: 0;
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        opacity: 0.2;
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        width: 90%;
    }

    .advantageBoxBgLeft2 {
        position: absolute;
        top: -6%;
        left: 7%;
        z-index: 1;
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        opacity: 0.4;
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        width: 88%;
    }

    .advantageBoxLeft {
        position: absolute;
        top: -12%;
        left: 12%;
        z-index: 2;
    }

    .advantageBoxBgRight1 {
        z-index: 0;
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        opacity: 0.2;
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        width: 90%;
        // float: right;
    }

    .advantageBoxBgRight2 {
        position: absolute;
        top: -6%;
        right: 7%;
        z-index: 1;
        background: linear-gradient(105.87deg, rgba(0, 0, 0, 0.4) 3.04%, rgba(0, 0, 0, 0) 100.02%);
        opacity: 0.4;
        box-shadow: inset 0px 0px 80px rgba(58, 163, 226, 0.8);
        backdrop-filter: blur(5px);
        border-radius: 15px;
        width: 88%;
    }

    .advantageBoxRight {
        position: absolute;
        top: -12%;
        right: 12%;
        z-index: 2;
    }

    #advantageRight {
        // margin-top: 20vh;
        height: 20vh;
    }


    .imageBoxIconSize {
        width: 40px;
    }

    .missionBg {
        background-image: url(../img/home/missionBg.png);
        background-size: cover;
        min-height: 110vh;
        background-position: center;
        display: flex;
        align-items: center;

        @media (max-width: 991px) {
            min-height: auto;
        }

        @media (max-width: 677px) {
            min-height: 110vh;
        }
    }

    .robotHandBg {
        background-image: url(../img/home/robotHandBg.png);
        background-size: 100% 100%;
        background-position: center;
        min-height: 70vh;

        // @media (max-width: 991px) {
        //     font-size: 16px;
        //     line-height: 23px;
        // }

        @media (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .robotHandImg {
        width: 50%;
        margin-top: -30%;

        @media (max-width: 991px) {
            width: 80%;
            margin-top: 0%;
        }
    }

    .robotHandLightPos {
        width: 80%;
        mix-blend-mode: screen;
        position: absolute;
        margin-top: -68%;
        top: 0;
        right: 0;

        @media (max-width: 991px) {
            width: 100%;
            top: 20%;
            right: -17%;
        }

        @media (max-width: 667px) {
            width: 70%;
            top: 33%;
            right: 0%;
        }
    }

    .lightFade1 {
        animation-name: LightFade2;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-top: 5px;
    }

    .lightFade3 {
        animation-name: LightFade;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        margin-top: 5px;
    }


    @keyframes LightFade {
        0% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes LightFade2 {
        0% {
            opacity: 0;
            transform: scale(0.2);
        }

        50% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(0.2);
        }
    }

    .choiceBg {
        background-image: url(../img/home/choiceBg.png);
        background-size: 100% 100%;
        background-position: center;
        min-height: 60vh;

        @media (max-width: 991px) {
            background-size: cover;
            background-position: center;
        }
    }

    .BgDecPos1 {
        position: absolute;
        top: 0%;
        left: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .BgDecPos2 {
        position: absolute;
        top: 0%;
        right: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .BgDecPos3 {
        position: absolute;
        top: -10%;
        left: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .BgDecPos4 {
        position: absolute;
        top: 25%;
        right: 0;

        img {
            mix-blend-mode: screen;
        }
    }

    .copy-box {
        position: relative;
        display: inline-block;
        margin: 0 50px 0 0px;
        width: fit-content;
        height: 400px;
    }

    .copy-box .line {
        position: absolute;
        background-color: aqua;
        box-shadow: 0px 0px 12px aqua;
        width: 40px;
        height: calc(100% - 20px);
        top: 10px;
        left: 22px;
        border-radius: 50%;
        overflow: hidden;
    }

    .copy-box.two .line {
        border-radius: 0;
        width: 2px;
    }

    .copy-box .line .scanner {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        width: 100%;
        height: 20px;
        border-radius: 50%;
        animation: scanner-loop 3s ease-in-out infinite;
    }

    .copy-box.two .line .scanner {
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
        height: 50%;
    }

    @keyframes scanner-loop {
        0% {
            top: 0;
        }

        50% {
            top: 100%;
        }

        100% {
            top: 0;
        }
    }

    .copy-box .inner {
        // padding: 30px;
    }

    .buttonGradientCenterAnim {

        &::after {
            position: absolute;
            content: "";
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;
            height: 50px;
            width: 220px;
            transform: scale(0.9) translateZ(0);
            filter: blur(15px);
            background: linear-gradient(to left,
                    #ff5770,
                    #e4428d,
                    #c42da8,
                    #9e16c3,
                    #6501de,
                    #9e16c3,
                    #c42da8,
                    #e4428d,
                    #ff5770);
            background-size: 200% 200%;
            animation: animateGlow 1.25s linear infinite;
        }
    }

    .buttonGradientLeftAnim {
        &::after {
            position: absolute;
            content: "";
            top: 40px;
            left: 0;
            z-index: 0;
            height: 50px;
            width: 220px;
            transform: scale(0.9) translateZ(0);
            filter: blur(15px);
            background: linear-gradient(to left,
                    #ff5770,
                    #e4428d,
                    #c42da8,
                    #9e16c3,
                    #6501de,
                    #9e16c3,
                    #c42da8,
                    #e4428d,
                    #ff5770);
            background-size: 200% 200%;
            animation: animateGlow 1.25s linear infinite;
        }

    }

    @keyframes animateGlow {
        0% {
            background-position: 0% 50%;
        }

        100% {
            background-position: 200% 50%;
        }
    }

    .circle-container {
        $particleWidth: 15px;
        $particleNum: 50;
        $particleColor: hsl(50, 100%, 80%);
        $particleColorA: hsla(50, 100%, 80%, 0);

        position: fixed;
        transform: translateY(-10vh);
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        z-index: 10000;

        .circle {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            mix-blend-mode: screen;
            background-image: radial-gradient($particleColor,
                    $particleColor 10%,
                    $particleColorA 56%);

            animation: fadein-frames 200ms infinite, scale-frames 2s infinite;

            @keyframes fade-frames {
                0% {
                    opacity: 1;
                }

                50% {
                    opacity: 0.7;
                }

                100% {
                    opacity: 1;
                }
            }

            @keyframes scale-frames {
                0% {
                    transform: scale3d(0.4, 0.4, 1);
                }

                50% {
                    transform: scale3d(2.2, 2.2, 1);
                }

                100% {
                    transform: scale3d(0.4, 0.4, 1);
                }
            }
        }

        @for $i from 1 through $particleNum {
            &:nth-child(#{$i}) {
                $circleSize: random($particleWidth);
                width: $circleSize + px;
                height: $circleSize + px;

                $startPositionY: random(10)+100;
                $framesName: "move-frames-"+$i;
                $moveDuration: 7000+random(5000)+ms;

                animation-name: #{$framesName};
                animation-duration: $moveDuration;
                animation-delay: random(11000) + ms;

                @keyframes #{$framesName} {
                    from {
                        transform: translate3d(#{random(100) + vw},
                            #{$startPositionY + vh},
                            0);
                    }

                    to {
                        transform: translate3d(#{random(100) + vw},
                            #{- $startPositionY - random(30) + vh},
                            0);
                    }
                }

                .circle {
                    animation-delay: random(5000) + ms;
                }
            }
        }
    }

}