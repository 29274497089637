.navBarLogo {
    width: 150px;

    @media (max-width: 677px) {
        width: 90px;
    }
}

.navbar-black {
    background-color: #000;
}

.navbar-toggler-bar {
    background: linear-gradient(119.73deg, rgba(155, 235, 255, 0.8) 45.01%, rgba(67, 173, 254, 0.8) 78.49%), #4A59FF !important;
}

.font-CN-Bold {
    font-family: "Urbanist-Bold";
}

.font-CN-Black {
    font-family: "Urbanist-Black";
}

.font-CN-Medium {
    font-family: "Urbanist-Medium";
}

.font-CN-Regular {
    font-family: "Urbanist-Regular";
}

.font-CN-Light {
    font-family: "Urbanist-Light";
}

.font-CN-Thin {
    font-family: "Urbanist-Thin";
}

.nav-item {
    display: flex;
    align-items: center;
}

.sidebar-collapse .navbar .dropdown.show .dropdown-menu,
.sidebar-collapse .navbar .dropdown .dropdown-menu {

    @media (max-width: 991px) {
        // margin: 0 !important;
    }
}

.navItemSelectedBg {
    // background: linear-gradient(92.32deg, #721DDD 4.16%, #3AA3E2 98.76%);
    // filter: blur(18px);
}

.navItemText {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.6);
    text-transform: capitalize;

    @media (max-width: 991px) {
        color: white;
    }
}

.footerTextHref {
    text-decoration: none !important;
}

.navItemTextHref {
    text-decoration: none !important;
    background: transparent !important;
}

.navbar-icon {
    color: #459DFF;

    @media (max-width: 991px) {
        color: white;
    }
}

.navbar-icon2 {
    color: #459DFF;
    display: none;

    @media (max-width: 991px) {
        display: none;
    }
}

.dropdown-toggle::after {
    color: #459DFF;
    font-size: 18px;
    margin-left: 8px;

    @media (max-width: 991px) {
        color: #fff;
    }
}


.learnMoreBtn {
    background: linear-gradient(119.73deg, rgba(155, 235, 255, 0.8) 45.01%, rgba(67, 173, 254, 0.8) 78.49%), #4A59FF;
    border-radius: 20px;

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: #767676;
    }
}

.navbar-nav .nav-item:not(:last-child) {
    @media screen and (min-width: 991px) {
        margin-right: 30px;
    }
}

@media screen and (max-width: 300px) {
    .sidebar-collapse [class*="navbar-expand-"] .navbar-collapse {
        width: 200px !important;
    }

    .nav-open .sidebar-collapse .navbar-translate {
        transform: translate3d(-200px, 0, 0);
    }

    .sidebar-collapse #bodyClick {
        right: 200px;
    }
}

.dropdown-menu-right {
    @media (min-width: 991px) {
        box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
    }
}

.dropdown-item {
    @media (min-width: 991px) {
        box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.17);
        background-color: white !important;
        color: black !important;
    }
}

.navbar-nav .nav-item .nav-link:not(.btn):hover {
    @media (max-width: 991px) {
        background-color: transparent !important;
    }
}

.sidebar-collapse .navbar-collapse:before {
    background: linear-gradient(#0018b6, #fff 90%) !important;
}

.footer {
    background: #00112A;
}

.footerLogoSize {
    width: 200px;
}

.selectedNavItem {
    // background-image: url(../img/home/navItemSelected.png);
    // background-size: cover;
    // background: linear-gradient(92.32deg, #721DDD 4.16%, #3AA3E2 98.76%);
    // filter: blur(13.5px);

    span {
        color: #FFFFFF;
        text-shadow: 0px 0px 9px #7DCCFF;
    }
}